import * as React from "react"
import { AnchorButton } from "gatsby-interface"

interface DeployToCloudButtonProps {
  repoUrl: string
}

const DeployToCloudIcon = () => (
  <svg
    fill="currentColor"
    height="20"
    width="20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM6.42857 17.5714C4.92857 16.0714 4.14286 14.0714 4.14286 12.1429L11.9286 19.8571C9.92857 19.7857 7.92857 19.0714 6.42857 17.5714ZM13.7143 19.6429L4.35714 10.2857C5.14286 6.78571 8.28571 4.14286 12 4.14286C14.6429 4.14286 16.9286 5.42857 18.3571 7.35714L17.2857 8.28571C16.0714 6.64286 14.1429 5.57143 12 5.57143C9.21429 5.57143 6.85714 7.35714 5.92857 9.85714L14.1429 18.0714C16.2143 17.3571 17.7857 15.5714 18.2857 13.4286H14.8571V12H19.8571C19.8571 15.7143 17.2143 18.8571 13.7143 19.6429Z" />
  </svg>
)

// TODO consider using the `deploynow.svg` instead of a bespoke `<Button>` with icon
// context: https://github.com/gatsby-inc/dotcom/pull/406#discussion_r961003185
const DeployToCloudButton = ({
  repoUrl,
  ...props
}: DeployToCloudButtonProps) => (
  <AnchorButton
    data-ld="btn-deploy-to-cloud"
    leftIcon={<DeployToCloudIcon />}
    size="M"
    href={`/dashboard/deploynow?url=${repoUrl}`}
    {...props}
  >
    Deploy to Gatsby Cloud
  </AnchorButton>
)

export default DeployToCloudButton
